
import axios from 'axios'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { Vue, Component } from 'vue-property-decorator'
import { Machine, interpret } from 'xstate'
import AttachmentDataApi from '@/api/maintain/attachment-filedata'
import { getShortUuid } from '@/libs/uuid'

// 文档地址 https://xstate.js.org

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LampContext{
  data?: string;
  vm: Vue;
}

/**
 *
 */
interface LampSchma{
  states: {
    red: {};
    yellow: {};
    green: {};
  };
}

type LampEvent={
  type: 'TIMER';
}|{type: 'TRUNRED'}

const mechine = Machine<LampContext, LampSchma, LampEvent>({
  key: 'test1',
  initial: 'green',

  states: {
    green: {
      on: {
        TIMER: {
          target: 'yellow',
          actions: (context, event) => {
            context.data = '1111'
          },
          cond: (context, event) => {
            if (context.data === '2222') return true
            context.vm.$Message.error('不行')
            return false
          }
        },
        TRUNRED: 'red'
      }
    },
    yellow: {
      entry: (context, event) => {
        console.log(context.data)
      },

      on: {
        TIMER: 'red'
      }
    },
    red: {
      on: {
        TIMER: 'green'
      }
    }

  }
})

@Component({
  name: 'testXState'
})
export default class TestXState extends Vue {
  current: any={}

  service=interpret(mechine.withContext({
    data: '2222', vm: this
  }))

  created () {
    this.service.onTransition(state => {
      this.$set(this, 'current', state)
    }).start()
  }

  dotest () {
    const resp = this.service.send({ type: 'TIMER' })
    console.log(resp.value)
  }

  test () {
    axios.get('http://192.168.1.201:8510/v1/internal/ui/services')
  }

  doGet () {
    alert(this.current.value)
  }

  /// 上传
  handleUpload (file: File) {
    /// 获取文件类型
    const typeArray = file.name.split(/(\.)/g)
    console.log(typeArray)
    typeArray.shift()
    let type = ''
    if (typeArray?.length) type = typeArray.join('')
    /// 上传 包装为formData
    const formData = new FormData()
    formData.append('file', file)
    AttachmentDataApi.upload(getShortUuid() + type, formData).then((res) => {
      console.log(res)
    })
    return false
  }

  /// 下载
  down () {
    const link = document.createElement('a')
    link.download = 'vvCYLWtNqs8xwc6rapZjQt.xlsx'
    AttachmentDataApi.downFilePart(16, 4, '0bf8d6d8-bdbe-4b60-809c-96f58a75aae5', 1).then((res) => {
      const blob = new Blob([res.data])
      link.href = window.URL.createObjectURL(blob)
      link.click()
      window.URL.revokeObjectURL(link.href)
      document.removeChild(link)
    })
  }

  get curdata () {
    return this.current.value
  }

  testpromise (): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      resolve('hi')
    })
  }

  dotest2 () {
    this.service.send({ type: 'TRUNRED' })
  }

  testrequest () {
    axios.request({
      url: 'http://127.0.0.1:10001/test/test',
      method: 'GET'
    }).then((resp) => {
      console.log('data:' + resp.data.data)
    }).catch((err) => {
      console.log('err:' + err.message)
    })
  }

  es?: EventSourcePolyfill

  testflux () {
    this.es = new EventSourcePolyfill('http://127.0.0.1:10001/test/flux', {

    })

    this.es.onmessage = (msg) => {
      console.log(msg)
    }
    this.es.onerror = (err: any) => {
      console.log(err)
    }
  }
}
